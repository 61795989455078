.img-event-centre__header {
  background: #242424;
  font-family: 'Saira Condensed', sans-serif;
  box-sizing: border-box;
  height: 42px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 0 16px;
}
.img-event-centre__header-title {
  flex: 1;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}
.img-event-centre__header-live-text {
  width: 24px;
  height: 16px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
