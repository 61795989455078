.img-event-centre__wrapper {
  border: 1px solid #373737;
  border-radius: 8px;
  overflow: hidden;
}
.img-event-centre__wrapper.hide-header {
  border-top: 0;
  border-radius: 0 0 8px 8px;
}
.img-event-centre__wrapper .img-event-centre__external-widget {
  height: 369px;
}
.img-event-centre__wrapper iframe {
  border: 0;
}
